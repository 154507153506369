import "./App.css";
import { colors, hFontPrototype } from "./theme/Theme";
import useWebSocket from "react-use-websocket";
import { CookiesProvider, useCookies } from "react-cookie";
import useAuth from "./hooks/useAuth";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Ban from "./pages/Ban";

function App() {
  const { cookies, login, logout } = useAuth();

  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <div
        className="App"
        style={{ height: "100%", backgroundColor: colors.BACKGROUND }}
      >
        {cookies?.token ? (
          cookies.token === "ban" ? (
            <Ban />
          ) : (
            <Home />
          )
        ) : (
          <Login login={login} />
        )}
      </div>
    </CookiesProvider>
  );
}

export default App;
