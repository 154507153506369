import { Box, CardMedia, CircularProgress, Typography } from "@mui/material";
import { colors, hFontPrototype } from "../theme/Theme";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import StyledButtton from "../theme/components/StyledButton";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import sadCat from "../images/sadcat.gif";

const Ban = () => {
  const [isLoading, setLoading] = useState(false);
  const { cookies, logout } = useAuth();

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          mt: "40%",
          border: `3px solid ${colors.SUCCESS}`,
          borderRadius: 1,
          p: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: colors.MAINWHITE,
              fontFamily: hFontPrototype,
              textAlign: "center",
            }}
            variant="h5"
          >
            {`Ваш аккаунт был заблокирован за нарушение политики сервиса!`}
          </Typography>
          <Typography
            sx={{
              color: colors.MAINGRAY,
              fontFamily: hFontPrototype,
              textAlign: "center",
            }}
            variant="h6"
          >
            {`Бан до ${cookies?.name}`}
          </Typography>

          <Box sx={{ py: 1 }}>
            <CardMedia
              image={sadCat}
              sx={{ width: "128pt", height: "128pt" }}
            />
          </Box>
        </Box>
        <StyledButtton onClick={() => logout()} fullWidth>
          <LogoutRoundedIcon />
        </StyledButtton>
      </Box>
    </Box>
  );
};

export default Ban;
